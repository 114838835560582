import { Component, Vue } from "vue-property-decorator";
import { GET_DASHBOARD, GET_DASHBOARD_EACHTYPE, GET_DASHBOARD_GRAPH } from "../../../api/dashboard"
import { DTOBalanceInfo, MemberOnlineDTO, DTOYourDownline, ActiveDTO, GameDetailsDTO} from "./Home.dto"
import Model from "@/models/Model";
import moment from 'moment';
import VModal from 'vue-js-modal'
import vSelect from 'vue-select';

Vue.use(VModal, { componentName: 'modal' })


import 'vue-select/dist/vue-select.css';
@Component({
    components: {
        vSelect
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateFormat(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY') : ''
        },
    }
})
export default class Home extends Vue {
    public chartOptions = {
        points: [10, 0, 8, 2, 6, 4, 5, 5],
        chart: {
            type: 'spline'
        },
        title: 'Valid Amount',
        series: [
            {
                name: 'Winloss',
                data: [1,2,3]
            },
            {
                name: 'Valid Amount',
                data: [1,4,7]
            }
        ],
        responsive: {
            rules: [{
              condition: {
                maxWidth: 100,
                maxHeight: 100,
                minWidth: 100,
                minHeight: 100,
              }
            }]
        },
    }
    private model: Model;
    public gameTypeArr: Array<string> = ["ALLIN","HOLDEM","SIXPLUS","PLO5","PLO4"]
    public frequencyArr: Array<string> = ["Today","This week","This month"]
    public filterDashboard = {
        gameType: 'ALLIN',
        frequency: 'Today',
        newMemberfrequency: 'Today',
        activeUserfrequency: 'Today',
        eachTypefrequency: 'Today',
        allinfrequency: 'Today',
        holdemfrequency: 'Today'
    }
    private username = ""
    private cookieData: any = Vue.$cookies.get("login")
    public logoURL = '/img/logo_1.png'
    public baseUrl = (process.env.NODE_ENV === 'production') ? process.env.VUE_APP_BASE_URL : 'http://localhost:8081'
    public onlineNow = 0
    public balanceInfo: DTOBalanceInfo = {
        startDate: "",
        endDate: "",
        totalProfit: 0,
        totalWinloss: 0,
        totalTurnover: 0,
    }
    public memberOnline: MemberOnlineDTO = {
        startDate: "",
        endDate: "",
        newMember: 0,
    }
    public yourDownline: DTOYourDownline = {
        members: 0,
        agents: 0,
    }
    public activeUserVal: ActiveDTO = {
        startDate: "",
        endDate: "",
        activeUser: 0,
    }

    public GameDetail: GameDetailsDTO = {};

    constructor() {
        super();
        this.model = new Model();
    }

    public getColor(number){
        if(Math.sign(number) == -1) return '#d42222'
        if(Math.sign(number) == 1) return 'green'
        else return '#3067B4'
    }

    async mounted() {
        if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
            this.username = this.cookieData.mainUsername
        } else {
            this.username = this.cookieData.username
        }
        this.$nextTick(async () => {
            const loader = this.$loading.show({
                color: '#CFA137',
                loader: 'spinner',
                width: 145,
                height: 235,
                backgroundColor: '#000000',
                opacity: 0.5,
            });

            await this.getDashboard()
            loader.hide()
        })
    }
    
    private async getDashboard(newMemberFrom?, newMemberTo?, activeUserFrom?, activeUserTo?, dataFrom?, dataTo?, graphFrom?, graphTo?,
        allin?, holdem?, graphGameType?) {

        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });

        newMemberFrom = newMemberFrom || moment().startOf('days').toISOString()
        newMemberTo = newMemberTo || moment().endOf('days').toISOString()
        activeUserFrom = activeUserFrom || moment().startOf('days').toISOString()
        activeUserTo = activeUserTo || moment().endOf('days').toISOString()
        dataFrom = dataFrom || moment().startOf('days').toISOString()
        dataTo = dataTo || moment().endOf('days').toISOString()

        graphFrom = graphFrom || moment().startOf('days').toISOString()
        graphTo = graphTo || moment().endOf('days').toISOString()

        allin = allin || { startDate: moment().startOf('days').toISOString(), endDate: moment().endOf('days').toISOString() }
        holdem = holdem || { startDate: moment().startOf('days').toISOString(), endDate: moment().endOf('days').toISOString() }

        graphGameType = graphGameType || '["ALLIN"]'

        
        const resultData = await GET_DASHBOARD(this.username, newMemberFrom, newMemberTo, activeUserFrom, activeUserTo, dataFrom, dataTo, graphGameType)
        const resultGraph = await GET_DASHBOARD_GRAPH(this.username, graphFrom, graphTo, graphGameType)

        if (resultData.success) {
            this.balanceInfo.startDate = resultData.data.balance_info.start_date
            this.balanceInfo.endDate = resultData.data.balance_info.end_date
            this.balanceInfo.totalProfit = resultData?.data?.balance_info?.total_profit || 0
            this.balanceInfo.totalTurnover = resultData.data.balance_info.total_turnover
            this.balanceInfo.totalWinloss = resultData.data.balance_info.total_winloss
            this.yourDownline.members = resultData.data.your_downline.members
            this.yourDownline.agents = resultData.data.your_downline.agents
            this.onlineNow = resultData.data.online_now
            this.activeUserVal.startDate = resultData.data.active_user.start_date
            this.activeUserVal.endDate = resultData.data.active_user.end_date
            this.activeUserVal.activeUser = resultData.data.active_user.active_user
            this.memberOnline.startDate = resultData.data.new_member.start_date
            this.memberOnline.endDate = resultData.data.new_member.end_date
            this.memberOnline.newMember = resultData.data.new_member.new_member
        }

        const resultEachtypes = await GET_DASHBOARD_EACHTYPE(this.username, allin.startDate, allin.endDate, holdem.startDate, holdem.endDate)
        this.GameDetail = resultEachtypes.data

        if (resultGraph.success) {
            resultGraph.data.graph_wl_turnover.label.forEach((value, index) => {
                const winlose = resultGraph.data.graph_wl_turnover.winlose[index]
                resultGraph.data.graph_wl_turnover.winlose[index] = [moment(value).utc(true).toISOString(), winlose]
                const turnover = resultGraph.data.graph_wl_turnover.turnover[index]
                resultGraph.data.graph_wl_turnover.turnover[index] = [moment(value).utc(true).toISOString(), turnover]
            });
            this.chartOptions.series[0].data = resultGraph.data.graph_wl_turnover.winlose
            this.chartOptions.series[1].data = resultGraph.data.graph_wl_turnover.turnover
        }
      
        loader.hide()
    }

    changeFrequencyToRange(type: string) {
        switch (type) {
            case 'Today':
                return {
                    "startDate": moment().local().startOf('day').toISOString(),
                    "endDate": moment().local().endOf('day').toISOString()
                }
            case 'This week':
                return {
                    "startDate": moment().local(true).startOf('week').toISOString(),
                    "endDate": moment().local(true).endOf('week').toISOString(),
                }
            case 'This month':
                return {
                    "startDate": moment().local(true).startOf('month').toISOString(),
                    "endDate": moment().local(true).endOf('month').toISOString()
                }
            default:
                break;
        }
    }

    async changeInputValue() {
        const dataCurrent =  this.changeFrequencyToRange(this.filterDashboard.frequency)
        const newMember =  this.changeFrequencyToRange(this.filterDashboard.newMemberfrequency)
        const activeUser =  this.changeFrequencyToRange(this.filterDashboard.activeUserfrequency)
        const gameTypeArray = JSON.stringify([this.filterDashboard.gameType])
        const allin = this.changeFrequencyToRange(this.filterDashboard.allinfrequency)
        const holdem = this.changeFrequencyToRange(this.filterDashboard.holdemfrequency)
        await this.getDashboard(newMember?.startDate, newMember?.endDate, activeUser?.startDate, activeUser?.endDate, dataCurrent?.startDate, dataCurrent?.endDate, dataCurrent?.startDate, dataCurrent?.endDate, allin, holdem, gameTypeArray)
    }

    currency(value: any) {
        const val = (value / 1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

}
