var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("h1", { staticClass: "bold" }, [
            _vm._v(_vm._s(_vm.$t("DASHBOARD.DASHBOARD")))
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            {
              staticClass: "card-body",
              staticStyle: { "padding-top": "1.25rem" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("span", { staticClass: "bold gold-text" }, [
                    _vm._v(_vm._s(_vm.$t("DASHBOARD.BALANCE_INFO")))
                  ])
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "flex-container" }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "padding-left": "0.5rem", width: "100%" }
                      },
                      [
                        _c("v-select", {
                          staticClass: "style-chooser",
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: false,
                            options: _vm.gameTypeArr,
                            placeholder: "Allin or fold"
                          },
                          on: {
                            input: function($event) {
                              return _vm.changeInputValue("gameType")
                            }
                          },
                          model: {
                            value: _vm.filterDashboard.gameType,
                            callback: function($$v) {
                              _vm.$set(_vm.filterDashboard, "gameType", $$v)
                            },
                            expression: "filterDashboard.gameType"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { "padding-left": "0.5rem", width: "100%" }
                      },
                      [
                        _c("v-select", {
                          staticClass: "style-chooser",
                          staticStyle: { width: "100% height:50%" },
                          attrs: {
                            clearable: false,
                            options: _vm.frequencyArr,
                            placeholder: "Daily"
                          },
                          on: {
                            input: function($event) {
                              return _vm.changeInputValue("frequency")
                            }
                          },
                          model: {
                            value: _vm.filterDashboard.frequency,
                            callback: function($$v) {
                              _vm.$set(_vm.filterDashboard, "frequency", $$v)
                            },
                            expression: "filterDashboard.frequency"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { "padding-left": "0.75rem" }
                },
                [
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 balance-info-total-profit" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _c("img", {
                                staticClass: "logo-total-profit",
                                attrs: { src: _vm.logoURL, alt: "logo" }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-7 text-center mt-3" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-total-profit",
                                    staticStyle: { color: "#ffffff" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("DASHBOARD.TOTAL_PROFIT")
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "h3",
                                  {
                                    staticStyle: { color: "#f5bc27" },
                                    style: {
                                      color: _vm.getColor(
                                        _vm.balanceInfo.totalProfit
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.balanceInfo.totalProfit
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col my-1 mr-1 text-center border border-dark rounded py-3"
                        },
                        [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("DASHBOARD.AGENT_WIN_LOSS")) +
                                " "
                            )
                          ]),
                          _c(
                            "h3",
                            {
                              staticClass: "bold mt-3",
                              style: {
                                color: _vm.getColor(
                                  _vm.balanceInfo.totalWinloss
                                )
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.balanceInfo.totalWinloss
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col my-1 ml-1 text-center border border-dark rounded py-3"
                        },
                        [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("DASHBOARD.VALID_AMOUNT")) +
                                " "
                            )
                          ]),
                          _c("h3", { staticClass: "bold blue-text mt-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.balanceInfo.totalTurnover
                                  )
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-8" },
                    [
                      _c(
                        "keep-alive",
                        [
                          _c("highcharts", {
                            staticStyle: { height: "260px" },
                            attrs: { options: _vm.chartOptions }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row row-header-info" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("span", { staticClass: "bold gold-text" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.NEW_MEMBER")))
                ])
              ]),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("v-select", {
                    staticClass: "style-chooser",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "currency",
                      name: "currency",
                      clearable: false,
                      options: _vm.frequencyArr,
                      placeholder: "Daily"
                    },
                    on: {
                      input: function($event) {
                        return _vm.changeInputValue("newMemberfrequency")
                      }
                    },
                    model: {
                      value: _vm.filterDashboard.newMemberfrequency,
                      callback: function($$v) {
                        _vm.$set(_vm.filterDashboard, "newMemberfrequency", $$v)
                      },
                      expression: "filterDashboard.newMemberfrequency"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass: "col text-center rounded py-3 mt-3 ribbin-mini-box"
              },
              [
                _c("span", { staticClass: "bold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.NEW_MEMBER")) + " ")
                ]),
                _c("h4", { staticClass: "bold gold-text-lighter m-2" }, [
                  _vm._v(" " + _vm._s(_vm.memberOnline.newMember) + " ")
                ]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("dateFormat")(_vm.memberOnline.startDate)) +
                      " - " +
                      _vm._s(_vm._f("dateFormat")(_vm.memberOnline.endDate)) +
                      " "
                  )
                ])
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row row-header-info" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("span", { staticClass: "bold gold-text" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.YOUR_DOWNLINE")))
                ])
              ]),
              _c("div", { staticClass: "col-6" })
            ]),
            _c("div", { staticClass: "row row-content-info" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col text-center ribbin-mini-box rounded py-3 mt-3 mx-1"
                },
                [
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.MEMBER")) + " ")
                  ]),
                  _c("h4", { staticClass: "bold gold-text-lighter m-2" }, [
                    _vm._v(" " + _vm._s(_vm.yourDownline.members) + " ")
                  ]),
                  _vm._m(0)
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col text-center ribbin-mini-box rounded py-3 mt-3 mx-1"
                },
                [
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.AGENT")) + " ")
                  ]),
                  _c("h4", { staticClass: "bold gold-text-lighter m-2" }, [
                    _vm._v(" " + _vm._s(_vm.yourDownline.agents) + " ")
                  ]),
                  _vm._m(1)
                ]
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row row-header-info" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("span", { staticClass: "bold gold-text" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.ONLINE")))
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "col text-center ribbin-mini-box rounded py-3 mt-4"
              },
              [
                _c("span", { staticClass: "bold" }, [
                  _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.ONLINE_NOW")) + " ")
                ]),
                _c("h4", { staticClass: "bold gold-text-lighter m-2" }, [
                  _vm._v(" " + _vm._s(_vm.onlineNow) + " ")
                ]),
                _vm._m(2)
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row row-header-info" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("span", { staticClass: "bold gold-text" }, [
                  _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.ACTIVE")))
                ])
              ]),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("v-select", {
                    staticClass: "style-chooser",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "currency",
                      name: "currency",
                      clearable: false,
                      options: _vm.frequencyArr,
                      placeholder: "Daily"
                    },
                    on: {
                      input: function($event) {
                        return _vm.changeInputValue("activeUserfrequency")
                      }
                    },
                    model: {
                      value: _vm.filterDashboard.activeUserfrequency,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.filterDashboard,
                          "activeUserfrequency",
                          $$v
                        )
                      },
                      expression: "filterDashboard.activeUserfrequency"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row row-content-info" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col text-center ribbin-mini-box rounded py-3 mt-2"
                },
                [
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.ACTIVE_USERS")) + " ")
                  ]),
                  _c("h4", { staticClass: "gold-text-lighter m-2" }, [
                    _vm._v(" " + _vm._s(_vm.activeUserVal.activeUser) + " ")
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(_vm.activeUserVal.startDate)
                        ) +
                        " - " +
                        _vm._s(
                          _vm._f("dateFormat")(_vm.activeUserVal.endDate)
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]),
    false ? _c("div", { staticClass: "row" }, [_vm._m(3)]) : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(4),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("v-select", {
                    staticClass: "style-chooser",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "currency",
                      name: "currency",
                      clearable: false,
                      options: _vm.frequencyArr,
                      placeholder: "Daily"
                    },
                    on: {
                      input: function($event) {
                        return _vm.changeInputValue("frequency")
                      }
                    },
                    model: {
                      value: _vm.filterDashboard.allinfrequency,
                      callback: function($$v) {
                        _vm.$set(_vm.filterDashboard, "allinfrequency", $$v)
                      },
                      expression: "filterDashboard.allinfrequency"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(5),
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-1 gray-400 font-weight-light" }, [
                  _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.TOTAL_PROFIT")) + " ")
                ]),
                _c(
                  "h1",
                  {
                    staticClass: "font-weight-bold",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin
                          ? _vm.GameDetail.allin.total_profit
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.total_profit)
                            : 0
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]),
            _c("hr"),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.VALID_AMOUNT")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin
                          ? _vm.GameDetail.allin.turn_over
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.turn_over)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.BINGO")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin ? _vm.GameDetail.allin.bingo : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.bingo)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.BONUS")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin ? _vm.GameDetail.allin.bonus : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.bonus)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.MEMBER_WL_100")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin
                          ? _vm.GameDetail.allin.member_winloss
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.member_winloss)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.AGENT_WIN_LOSS")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin
                          ? _vm.GameDetail.allin.agent_winloss
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.agent_winloss)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s("PRIZEPOOL") + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin
                          ? _vm.GameDetail.allin.prize_pool
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.prize_pool)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s("COMMISSION") + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.allin
                          ? _vm.GameDetail.allin.commission
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.allin
                            ? _vm.currency(_vm.GameDetail.allin.commission)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(6),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("v-select", {
                    staticClass: "style-chooser",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "currency",
                      name: "currency",
                      clearable: false,
                      options: _vm.frequencyArr,
                      placeholder: "Daily"
                    },
                    on: {
                      input: function($event) {
                        return _vm.changeInputValue("frequency")
                      }
                    },
                    model: {
                      value: _vm.filterDashboard.holdemfrequency,
                      callback: function($$v) {
                        _vm.$set(_vm.filterDashboard, "holdemfrequency", $$v)
                      },
                      expression: "filterDashboard.holdemfrequency"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _vm._m(7),
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-1 gray-400 font-weight-light" }, [
                  _vm._v(" " + _vm._s(_vm.$t("DASHBOARD.TOTAL_PROFIT")) + " ")
                ]),
                _c(
                  "h1",
                  {
                    staticClass: "font-weight-bold",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.total_profit
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.total_profit)
                            : 0
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]),
            _c("hr"),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.VALID_AMOUNT")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.turn_over
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.turn_over)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.BONUS")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem ? _vm.GameDetail.holdem.bonus : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.bonus)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.MEMBER_WL_100")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.member_winloss
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.member_winloss)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("DASHBOARD.AGENT_WIN_LOSS")) + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.agent_winloss
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.agent_winloss)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s("JACKPOT") + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.jackpot
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.jackpot)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s("PRIZEPOOL") + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.prize_pool
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.prize_pool)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s("COMMISSION") + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.commission
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.commission)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "mb-3 gray-400 font-weight-light" }, [
                  _vm._v(_vm._s("BOF") + " ")
                ])
              ]),
              _c("div", { staticClass: "col d-flex justify-content-end" }, [
                _c(
                  "h5",
                  {
                    staticClass: "gold-text",
                    style: {
                      color: _vm.getColor(
                        _vm.GameDetail.holdem
                          ? _vm.GameDetail.holdem.bet_on_flop
                          : 0
                      )
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.GameDetail.holdem
                            ? _vm.currency(_vm.GameDetail.holdem.bet_on_flop)
                            : 0
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 mt-1",
                    staticStyle: { "font-weight": "lighter" }
                  },
                  [_vm._v("THB")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "card", staticStyle: { height: "20rem" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 m-4 bold gold-text" }, [
            _vm._v(" ACTIVE USERS STATIC 30 DAYS ")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-9" }, [
      _c("h3", { staticClass: "font-weight-bold" }, [_vm._v("ALLIN OR FOLD")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2" }, [
      _c(
        "div",
        {
          staticClass:
            "bg-green-200 rounded-circle h-100 d-flex justify-content-center"
        },
        [
          _c("h1", { staticClass: "align-self-center green-600" }, [
            _vm._v(" $ ")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-9" }, [
      _c("h3", { staticClass: "font-weight-bold" }, [_vm._v("Hold em")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2" }, [
      _c(
        "div",
        {
          staticClass:
            "rounded-circle bg-green-200 h-100 d-flex justify-content-center"
        },
        [
          _c("h1", { staticClass: "align-self-center green-600" }, [
            _vm._v(" $ ")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }