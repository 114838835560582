import API from './api'
import { axiosWithErrorHandler } from './axios'

export const GET_DASHBOARD = async (id: string, newMemberFrom: string, newMemberTo: string, activeUserFrom: string, activeUserTo: string, dataFrom: string, dataTo: string, gameType: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_DASHBOARD}/${id}?newMemberFrom=${newMemberFrom}&newMemberTo=${newMemberTo}&activeUserFrom=${activeUserFrom}&activeUserTo=${activeUserTo}&dataFrom=${dataFrom}&dataTo=${dataTo}&gameType=${gameType}`)
    return response.data;
};

export const GET_DASHBOARD_GRAPH = async (id: string, startDate: string, endDate: string, gameType: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_DASHBOARD_GRAPH}/${id}?gameType=${gameType}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;
};

export const GET_DASHBOARD_EACHTYPE = async (id: string, allinFrom: string, allinTo: string, holdemFrom, holdemTo) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_DASHBOARD_TYPES}/${id}?allinFrom=${allinFrom}&allinTo=${allinTo}&holdemFrom=${holdemFrom}&holdemTo=${holdemTo}`)
    return response.data;
};